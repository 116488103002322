import { combineReducers } from "redux";
import auth from "./auth/reducer";
import alert from "./Alerts/reducer";
import main from "./Main/reducer";
import exam from "./Exam/reducer";

const reducers = combineReducers({
  auth,
  alert,
  main,
  exam
});

export default reducers;
