// const baseUrl = "http://localhost:8000";
const baseUrl = "https://api.dbse.me";

// Auth
export const LoginUserAPI = `${baseUrl}/users/authenticate`; // POST { username, password }
export const RegisterUserAPI = `${baseUrl}/users/signup`; // POST { username, password, role }

// User Details
export const AddStep1DetailsAPI = `${baseUrl}/teacher/profile`; // POST
export const GetUserProfileDetailsAPI = `${baseUrl}/users/me`; // POST { games, period }

// export const GetTeacherClassesAPI = `${baseUrl}/exam/classes`; // GET
export const GetTeacherClassesAPI = `${baseUrl}/exam/currentClasses`; // GET
export const GetTeacherStudentsAPI = `${baseUrl}/exam/students`; // GET ADD {schId AND sectionID}
export const VerifyClassStatusAPI = `${baseUrl}/exam//class`; // GET ADD {/:schId/:classId/:section/:subject}

export const GetStudentDetailAPI = `${baseUrl}/exam/studentDetail`; // GET Add Student Id
export const GetPaperStyleAPI = `${baseUrl}/exam/paperStyle`; // GET Add {class, subject}


export const PostStudentMarksAPI = `${baseUrl}/exam/addMarks`; // POST Add {studentId, paperId, isPresent}
export const GetStudentMarksAPI = `${baseUrl}/exam/getMarks`; // GET STUDENT {studentId}
export const UpdateStudentMarksAPI = `${baseUrl}/exam/updateMarks`; // PATCH Add {studentId}

// Dashboard
export const GetTeacherStatsAPI = `${baseUrl}/dashboard/teacherStats`; // GET
export const GetDashboardStatsAPI = `${baseUrl}/dashboard/dashboardStats`; // GET

export { baseUrl };