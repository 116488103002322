import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { getTeacherStats } from "../../../../redux/Main/action";

import Table from "./Table";

const Summary = ({ getTeacherStats, teacherStats }) => {

  useEffect(() => {
    getTeacherStats()
    // eslint-disable-next-line
  }, [])

  const [filterTeacher, setFilterTeacher] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTeacher, setSearchTeacher] = useState("");

  useEffect(() => {
    if (teacherStats) {
      setFilterTeacher(teacherStats)
    }
  }, [teacherStats]);

  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val)
    const filtered = teacherStats.filter((item) => item.schoolId.toLowerCase().includes(val))
    setFilterTeacher(filtered)
  }
  const handleSearchTeacher = (e) => {
    let val = e.target.value;
    setSearchTeacher(val)
    const filtered = teacherStats.filter((item) => item.teacherId.toLowerCase().includes(val))
    setFilterTeacher(filtered)
  }
  const handleClassFilter = (e) => {
    let val = e;
    let arr = new Set()
    val.forEach((item) => {
      arr.add(item.value)
    })
    const filtered = teacherStats.filter((item) => arr.has(item.classId))
    setFilterTeacher(filtered)
  }
  const handleSectionFilter = (e) => {
    let val = e;
    let arr = new Set()
    val.forEach((item) => {
      arr.add(item.value)
    })
    const filtered = teacherStats.filter((item) => arr.has(item.section))
    setFilterTeacher(filtered)
  }
  const handleSubjectFilter = (e) => {
    let val = e;
    let arr = new Set()
    val.forEach((item) => {
      arr.add(item.value)
    })
    const filtered = teacherStats.filter((item) => arr.has(item.subject))
    setFilterTeacher(filtered)
  }
  const handleStatusFilter = (e) => {
    let val = e.value;
    const filtered = teacherStats.filter((item) => val ? item.isVerified !== null : item.isVerified === null)
    setFilterTeacher(filtered)
  }


  const classGroup = [
    {
      options: [
        { label: "Class 1", value: "1" },
        { label: "Class 2", value: "2" },
        { label: "Class 3", value: "3" },
        { label: "Class 4", value: "4" },
        { label: "Class 5", value: "5" },
        { label: "Class 6", value: "6" },
        { label: "Class 7", value: "7" },
        { label: "Class 8", value: "8" },
        { label: "Class 9", value: "9" },
        { label: "Class 10", value: "10" },
        { label: "Class 11", value: "11" },
        { label: "Class 12", value: "12" },
      ],
    },
  ];

  const sectionGroup = [
    {
      options: [
        { label: "A", value: "A" },
        { label: "B", value: "B" },
        { label: "C", value: "C" },
        { label: "D", value: "D" },
        { label: "E", value: "E" },
      ],
    },
  ];
  const subjectGroup = [
    {
      options: [
        { label: "English", value: "English" },
        { label: "Hindi", value: "Hindi" },
        { label: "Maths", value: "Maths" },
        { label: "Social Science", value: "Social Science" },
        { label: "Science", value: "Science" },
        { label: "Physical", value: "Physical" },
      ],
    },
  ];
  const statusGroup = [
    {
      options: [
        { label: "Pending", value: 0},
        { label: "Sucess" , value: 1},
      ],
    },
  ];

  return (
    <div className="page-content" style={{ minHeight: "80vh" }}>
      <Container fluid>
        <Row>

          <Col md={6}>
            <Form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <Input
                  style={{ backgroundColor: "#fff" }}
                  type="text"
                  className="form-control"
                  placeholder="School ID"
                  value={search}
                  onChange={handleSearch}
                />
                <span className="ri-search-line"></span>
              </div>
            </Form>
          </Col>
          <Col md={6}>
            <Form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <Input
                  style={{ backgroundColor: "#fff" }}
                  type="text"
                  className="form-control"
                  placeholder="Teacher ID"
                  value={searchTeacher}
                  onChange={handleSearchTeacher}
                />
                <span className="ri-search-line"></span>
              </div>
            </Form>
          </Col>

        </Row>

        <Row>
          <Col lg={8}>
            {teacherStats && <Table teacherStats={filterTeacher} />}
          </Col>

          <Col lg={4} className="pl-4 pr-4">
            <Row>
              <FormGroup className="select2-container w-100">
                <Label>Class</Label>
                <Select
                  options={classGroup}
                  classNamePrefix="select2-selection"
                  isMulti={true}
                  onChange={handleClassFilter}
                />
              </FormGroup>
            </Row>

            <Row>
              <FormGroup className="select2-container w-100">
                <Label>Section</Label>
                <Select
                  options={sectionGroup}
                  classNamePrefix="select2-selection"
                  isMulti={true}
                  onChange={handleSectionFilter}
                />
              </FormGroup>
            </Row>

            <Row>
              <FormGroup className="select2-container w-100">
                <Label>Subject</Label>
                <Select
                  options={subjectGroup}
                  classNamePrefix="select2-selection"
                  isMulti={true}
                  onChange={handleSubjectFilter}
                />
              </FormGroup>
            </Row>

            <Row>
              <FormGroup className="select2-container w-100">
                <Label>Status</Label>
                <Select
                  options={statusGroup}
                  classNamePrefix="select2-selection"
                  onChange={handleStatusFilter}
                />
              </FormGroup>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ main }) => ({
  teacherStats: main.teacherStats
});

export default connect(mapStateToProps, { getTeacherStats })(Summary);
