import React, { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { Button } from "reactstrap";

import { Link, useHistory } from "react-router-dom";

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import { connect, useSelector } from "react-redux";
import setDefaultToken from "../../helpers/localstorage";
import { userLogout } from "../../redux/auth/actions";

const Header = ({ toggleMenu, userLogout }) => {

  const history = useHistory();
  const { token } = useSelector((state) => state.auth);
  
  useEffect(() => {
    var decoded = jwt_decode(token);
    if (decoded.exp < (new Date().getTime() + 1) / 1000) {
      console.log("Token Expired!! Logging Out. Stay Secure. :)")
      userLogout("", history);
    }
    setDefaultToken("Authorization", token);
    // eslint-disable-next-line
  }, [token]);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">

            <div className="navbar-brand-box">
              <Link to="#" className="logo logo-dark">
                <span className="logo-sm">
                  {/* <img src={logosmdark} alt="" height="22" /> */}
                  DBSE MIS
                </span>
                <span className="logo-lg">
                  {/* <img src={logodark} alt="" height="20" /> */}
                  DBSE MIS
                </span>
              </Link>

              <Link to="#" className="logo logo-light">
                <span className="logo-sm">
                  {/* <img src={logosmlight} alt="" height="22" /> */}
                  DBSE MIS
                </span>
                <span className="logo-lg">
                  {/* <img src={logolight} alt="" height="20" /> */}
                  DBSE MIS
                </span>
              </Link>
            </div>

            <Button size="sm" color="none" type="button" onClick={toggleMenu} className="px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn">
              <i className="ri-menu-2-line align-middle"></i>
            </Button>

          </div>

          <div className="d-flex">
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

export default connect(null, { userLogout })(Header);
