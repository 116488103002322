
// import { Summary } from "devextreme-react/data-grid";
import Assesments from "../Components/Assesments";
import Curriculum from "../Components/Curriculum";
import Stats from "../Components/Stats";
import Summary from "../Components/Summary";

export const DashboardRoutes = [
  {
    Component: Stats,
    path: "/stats",
    exact: true,
    id: "dashboardStats",
    routePath: "/dashboard/stats",
    name: "Stats",
    icon: "ri-home-fill",
    visibleTo: ["Admin"]
  },
  {
    Component: Summary,
    path: "/summary",
    exact: true,
    id: "dashboardSummary",
    routePath: "/dashboard/summary",
    name: "Summary",
    icon: "ri-home-line",
    visibleTo: ["Admin"]
  },
  {
    Component: Assesments,
    path: "/classes",
    exact: true,
    id: "dashboardClasses",
    routePath: "/dashboard/classes",
    name: "Assessments",
    icon: "ri-file-fill",
    visibleTo: ["Admin", "Teacher"]
  },
  {
    Component: Curriculum,
    path: "/curriculum",
    exact: true,
    id: "dashboardCurriculum",
    routePath: "/dashboard/curriculum",
    name: "Curriculum",
    icon: "ri-flashlight-fill",
    visibleTo: ["Admin", "Teacher"]
  },
];
