import React from "react";
import { DashboardRoutes } from "./Constants/routes";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import StudentList from "./Components/StudentList";
import Report from "./Components/Report";
import Paper from "./Components/Paper/";
import { connect } from "react-redux";

const Dashboard = ({ role }) => {
  const match = useRouteMatch();
  return (
    <div>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/classes`} />
        {DashboardRoutes.map(({ path, Component, exact, id, visibleTo }) => { // eslint-disable-line
          if (visibleTo.includes(role)) {
            return (
              <Route
                path={`${match.url}${path}`}
                exact={exact}
                component={Component}
                key={id}
              />
            )
          }
        }
        )}
        <Route exact path={"/dashboard/report"} component={Report} />
        <Route exact path={"/dashboard/students/:schId/:classId/:sectionId/:subject"} component={StudentList} />
        <Route exact path={"/dashboard/paper/:schId/:classId/:sectionId/:subject/:stuId"} component={Paper} />
        {role === "Admin" ? (
          <Redirect to="/dashboard/stats" />
        ) : (
          <Redirect to="/dashboard/classes" />
        )}
      </Switch>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  role: auth.role,
});

export default connect(mapStateToProps, null)(Dashboard);
