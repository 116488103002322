// USER REGISTER AUTH
export const USER_REGISTER = "USER_REGISTER";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";

// USER LOGIN AUTH
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";

// USER LOGOUT AUTH
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_ERROR = "USER_LOGOUT_ERROR";

// ALERTS
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// POST USER DETAILS STEP 1
export const POST_USER_DETAILS = "POST_USER_DETAILS";
export const POST_USER_DETAILS_SUCCESS = "POST_USER_DETAILS_SUCCESS";
export const POST_USER_DETAILS_ERROR = "POST_USER_DETAILS_ERROR";

// GET USER DETAILS
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_ERROR = "GET_USER_DETAILS_ERROR";


export const SELECT_CLASS_TYPE = "SELECT_CLASS_TYPE";
export const SELECT_CURRENT_STUDENT = "SELECT_CURRENT_STUDENT";

// GET TEACHER CLASSES
export const GET_TEACHER_CLASSES = "GET_TEACHER_CLASSES";
export const GET_TEACHER_CLASSES_SUCCESS = "GET_TEACHER_CLASSES_SUCCESS";
export const GET_TEACHER_CLASSES_ERROR = "GET_TEACHER_CLASSES_ERROR";

// GET TEACHER STUDENTS
export const GET_TEACHER_STUDENTS = "GET_TEACHER_STUDENTS";
export const GET_TEACHER_STUDENTS_SUCCESS = "GET_TEACHER_STUDENTS_SUCCESS";
export const GET_TEACHER_STUDENTS_ERROR = "GET_TEACHER_STUDENTS_ERROR";

// GET STUDENT DETAIL
export const GET_STUDENT_DETAIL = "GET_STUDENT_DETAIL";
export const GET_STUDENT_DETAIL_SUCCESS = "GET_STUDENT_DETAIL_SUCCESS";
export const GET_STUDENT_DETAIL_ERROR = "GET_STUDENT_DETAIL_ERROR";

// GET PAPER STYLE
export const GET_PAPER_STYLE = "GET_PAPER_STYLE";
export const GET_PAPER_STYLE_SUCCESS = "GET_PAPER_STYLE_SUCCESS";
export const GET_PAPER_STYLE_ERROR = "GET_PAPER_STYLE_ERROR";

// POST STUDENT MARKS
export const POST_STUDENT_MARKS = "POST_STUDENT_MARKS";
export const POST_STUDENT_MARKS_SUCCESS = "POST_STUDENT_MARKS_SUCCESS";
export const POST_STUDENT_MARKS_ERROR = "POST_STUDENT_MARKS_ERROR";

// GET STUDENT MARKS
export const GET_STUDENT_MARKS = "GET_STUDENT_MARKS";
export const GET_STUDENT_MARKS_SUCCESS = "GET_STUDENT_MARKS_SUCCESS";
export const GET_STUDENT_MARKS_ERROR = "GET_STUDENT_MARKS_ERROR";

// UPDATE STUDENT MARKS
export const UPDATE_STUDENT_MARKS = "UPDATE_STUDENT_MARKS";
export const UPDATE_STUDENT_MARKS_SUCCESS = "UPDATE_STUDENT_MARKS_SUCCESS";
export const UPDATE_STUDENT_MARKS_ERROR = "UPDATE_STUDENT_MARKS_ERROR";

// GET TEACHER STATS
export const GET_TEACHER_STATS = "GET_TEACHER_STATS";
export const GET_TEACHER_STATS_SUCCESS = "GET_TEACHER_STATS_SUCCESS";
export const GET_TEACHER_STATS_ERROR = "GET_TEACHER_STATS_ERROR";

// GET DASHBOARD STATS
export const GET_DASHBOARD_STATS = "GET_DASHBOARD_STATS";
export const GET_DASHBOARD_STATS_SUCCESS = "GET_DASHBOARD_STATS_SUCCESS";
export const GET_DASHBOARD_STATS_ERROR = "GET_DASHBOARD_STATS_ERROR";

// GET DASHBOARD STATS
export const MARK_CLASS_STATUS_VERIFIED = "MARK_CLASS_STATUS_VERIFIED";
export const MARK_CLASS_STATUS_VERIFIED_SUCCESS = "MARK_CLASS_STATUS_VERIFIED_SUCCESS";
export const MARK_CLASS_STATUS_VERIFIED_ERROR = "MARK_CLASS_STATUS_VERIFIED_ERROR";