import {
    POST_USER_DETAILS,
    POST_USER_DETAILS_SUCCESS,
    POST_USER_DETAILS_ERROR,
    GET_USER_DETAILS,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_ERROR,
    GET_TEACHER_STATS,
    GET_TEACHER_STATS_SUCCESS,
    GET_TEACHER_STATS_ERROR,
    GET_DASHBOARD_STATS,
    GET_DASHBOARD_STATS_SUCCESS,
    GET_DASHBOARD_STATS_ERROR,
    MARK_CLASS_STATUS_VERIFIED,
    MARK_CLASS_STATUS_VERIFIED_SUCCESS,
    MARK_CLASS_STATUS_VERIFIED_ERROR
} from "../actions";


export const addTeacherData = (payload) => ({
    type: POST_USER_DETAILS,
    payload
});
export const addTeacherDataSuccess = (payload) => ({
    type: POST_USER_DETAILS_SUCCESS,
    payload
});
export const addTeacherDataError = (payload) => ({
    type: POST_USER_DETAILS_ERROR,
    payload
});

export const getUserDetails = () => ({
    type: GET_USER_DETAILS
});
export const getUserDetailsSuccess = (payload) => ({
    type: GET_USER_DETAILS_SUCCESS,
    payload
});
export const getUserDetailsError = (payload) => ({
    type: GET_USER_DETAILS_ERROR,
    payload
});

export const getTeacherStats = () => ({
    type: GET_TEACHER_STATS
});
export const getTeacherStatsSuccess = (payload) => ({
    type: GET_TEACHER_STATS_SUCCESS,
    payload
});
export const getTeacherStatsError = (payload) => ({
    type: GET_TEACHER_STATS_ERROR,
    payload
});

export const getDashboardStats = () => ({
    type: GET_DASHBOARD_STATS
});
export const getDashboardStatsSuccess = (payload) => ({
    type: GET_DASHBOARD_STATS_SUCCESS,
    payload
});
export const getDashboardStatsError = (payload) => ({
    type: GET_DASHBOARD_STATS_ERROR,
    payload
});

export const markClassVerified = (payload) => ({
    type: MARK_CLASS_STATUS_VERIFIED,
    payload
});
export const markClassVerifiedSuccess = (payload) => ({
    type: MARK_CLASS_STATUS_VERIFIED_SUCCESS,
    payload
});
export const markClassVerifiedError = (payload) => ({
    type: MARK_CLASS_STATUS_VERIFIED_ERROR,
    payload
});