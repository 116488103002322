import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import 'devextreme/dist/css/dx.light.css';
// import { Popup } from 'devextreme-react/popup';
import FileManager, { Permissions } from 'devextreme-react/file-manager';

const Curriculum = () => {

    useEffect(() => {
        document.querySelector("body").classList.add("dx-viewport")
    }, [])

    const breadcrumbItems = [
        { title: "Home", link: "/dashboard" },
        { title: "Curriculum", link: "#" }
    ];

    var fileSystem = [
        {
            name: "Grade 1 - 8",
            size: 1024,
            dateModified: "2022/06/30",
            // thumbnail: "/thumbnails/images/jpeg.ico",
            isDirectory: true,
            items: [
                {
                    name: "Grade 8",
                    size: 1024,
                    dateModified: "2022/06/30",
                    isDirectory: true,
                    items: [
                        {
                            name: "English",
                            size: 1024,
                            dateModified: "2022/06/30",
                            isDirectory: true,
                            items: [
                                {
                                    name: "Unit 1",
                                    size: 1024,
                                    dateModified: "2022/06/30",
                                    isDirectory: true,
                                    items: [
                                        {
                                            name: "Unit Plan.pdf",
                                            size: 1024,
                                            dateModified: "2022/06/30",
                                            isDirectory: false,
                                            items: []
                                        },
                                        {
                                            name: "Student Companion.pdf",
                                            size: 1024,
                                            dateModified: "2022/06/30",
                                            isDirectory: false,
                                            items: []
                                        },
                                        {
                                            name: "Pedagogic Companion.pdf",
                                            size: 1024,
                                            dateModified: "2022/06/30",
                                            isDirectory: false,
                                            items: []
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
            ]
        },
        {
            name: "Grade 9 - 12",
            size: 1024,
            dateModified: "2022/06/30",
            isDirectory: true,
            items: []
        },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Curriculum"
                        breadcrumbItems={breadcrumbItems}
                        showBtn={false}
                    />

                    <Row>
                        <Col xs={12}>

                            <FileManager
                                fileSystemProvider={fileSystem}
                                // currentPath={this.state.currentPath}
                                // fileSystemProvider={remoteProvider}
                                // onSelectedFileOpened={this.displayImagePopup}
                                // onCurrentDirectoryChanged={this.onCurrentDirectoryChanged}>
                            >
                                <Permissions
                                    create={true}
                                    copy={true}
                                    move={true}
                                    delete={true}
                                    rename={true}
                                    upload={true}
                                    download={true}>
                                </Permissions>
                            </FileManager>

                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment >
    )
}

export default Curriculum;
