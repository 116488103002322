import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Label,
  FormGroup,
  Input,
  CardFooter,
  Button,
} from "reactstrap";
// Editable
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  addStudentMarks,
  getPaperStyle,
  getStudentDetail,
} from "../../../../redux/Exam/action";
import Select from "react-select";

const Paper = ({
  getStudentDetail,
  studentDetails,
  getPaperStyle,
  paperStyle,
  addStudentMarks,
  teacherStudents,
}) => {
  const { schId, classId, sectionId, subject, stuId } = useParams();
  const [isPresent, setIsPresent] = useState(true);

  const history = useHistory();

  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: `${classId} ${subject} Paper`, link: "#" },
  ];

  const [stu, setStu] = useState({
    name: "",
    enrolNo: stuId,
    class: classId,
    section: "",
    subject: subject,
    status: null,
  });

  const [questionsData, setQuestionsData] = useState([]);
  const [marksData, setMarksData] = useState([]);
  const [column, setColumn] = useState([]);

  useEffect(() => {
    getStudentDetail(stuId);
    getPaperStyle({ classId, subject }, stuId);
    // eslint-disable-next-line
  }, [classId, subject, stuId]);

  useEffect(() => {
    setStu({
      name: studentDetails.name,
      enrolNo: studentDetails.enrolNo,
      class: studentDetails.class,
      section: studentDetails.Section,
      subject: studentDetails.subject,
    });
  }, [studentDetails, classId, subject, stuId]);

  const optionGroup = [
    {
      options: [
        { label: "AB = Absent", value: "AB)" },
        { label: "ML = Absent due to medical reasons (certificate needed)", value: "ML)" },
        { label: "EC = Absent due to exceptional circumstances (reasons beyond students' control) - (proof required)", value: "EC" },
        { label: "TC = Transfer certificate issued", value: "TC" }
      ],
    },
  ];

  const tableColumns = [
    {
      dataField: "question",
      text: "Tasks",
      sort: false,
    },
    {
      dataField: "type",
      text: "Type",
      sort: false,
      editable: false,
    },
    // {
    //   dataField: "crit_A_max",
    //   text: "Max Score Points Criteria B",
    //   sort: false,
    //   editable: false
    // },
    // {
    //   dataField: "crit_C_max",
    //   text: "Max Score Points Criteria C",
    //   sort: false,
    //   editable: false
    // },
  ];

  useEffect(() => {
    if (paperStyle.questions && paperStyle.questions.length !== 0) {
      if (paperStyle.isPresent !== undefined && paperStyle.isPresent !== true) {
        setIsPresent(paperStyle.isPresent || !isPresent);
      }
      setQuestionsData(paperStyle.questions);
      let cols = [];
      let questions = paperStyle.questions;
      questions.forEach((ques) => {
        let criterias = ques.criteria;
        criterias &&
          criterias.map((item) => {
            if (!cols.includes(item.id)) {
              cols.push(item.id);
              tableColumns.push({
                dataField: item.id,
                text: item.name,
                sort: true,
                editable: (cell, row, rowIndex, colIndex) => {
                  if (row[`${item.id}`] !== undefined) return true;
                },
                validator: (newValue, row, column) => {
                  if (isNaN(newValue)) {
                    return { valid: false, message: "Marks should be numeric" };
                  }
                  if (newValue < 0) {
                    return {
                      valid: false,
                      message: "Marks should be greater than 0",
                    };
                  }
                  if (newValue > parseInt(item.validation)) {
                    return {
                      valid: false,
                      message: `Marks should less than or equal to ${item.validation}`,
                    };
                  }
                  return true;
                },
              });
            }
            return 0;
          });
      });

      setColumn(tableColumns);

      const copy = [];
      for (let i = 0; i < paperStyle.questions.length; i++) {
        copy[i] = paperStyle.questions[i];
      }
      setMarksData(copy);
    }
    // eslint-disable-next-line
  }, [paperStyle]);

  const handleUpdate = (newValue, row, column) => {
    const copy = [];
    for (let i = 0; i < marksData.length; i++) {
      copy[i] = marksData[i];
    }
    copy[row.id][`${column.dataField}`] = newValue;
    setMarksData(copy);
  };

  const handleSubmit = () => {
    addStudentMarks({
      studentId: stuId,
      paperId: paperStyle.paperId,
      isPresent: isPresent,
      marks: marksData,
      subject: subject,
      schoolId: schId,
      section: sectionId,
      classId: classId
    });
    // schId, classId, sectionId, subject, stuId
    // console.log({ studentId: stuId, paperId: paperStyle.paperId, isPresent: isPresent, marks: marksData })
  };

  const handleSubmitAndNext = () => {
    teacherStudents.forEach((item, i) => {
      if (item.StudentID === stuId) {
        handleSubmit();
        history.push(
          `/dashboard/paper/${schId}/${classId}/${sectionId}/${subject}/${
            teacherStudents[i + 1].StudentID
          }`
        );
      }
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={`${subject} Paper`}
            breadcrumbItems={breadcrumbItems}
            showBtn={true}
            handleClick={() => {
              history.push(
                `/dashboard/students/${schId}/${classId}/${sectionId}/${subject}`
              );
            }}
          />

          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Col lg={6}>
                    <FormGroup row className="">
                      <Label
                        htmlFor="enrolNo"
                        className="col-md-2 col-form-label"
                      >
                        Roll Number
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          disabled={true}
                          // defaultValue={stuId}
                          value={stuId}
                          id="enrolNo"
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label htmlFor="name" className="col-md-2 col-form-label">
                        Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          disabled={true}
                          defaultValue={stu.name}
                          id="name"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="class"
                        className="col-md-2 col-form-label"
                      >
                        Class
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          disabled={true}
                          defaultValue={stu.class}
                          id="class"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="section"
                        className="col-md-2 col-form-label"
                      >
                        Section
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          disabled={true}
                          defaultValue={stu.section}
                          id="section"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="subject"
                        className="col-md-2 col-form-label"
                      >
                        Subject
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          disabled={true}
                          defaultValue={stu.subject}
                          id="subject"
                        />
                      </Col>
                    </FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr"
                    >
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitch1"
                        checked={isPresent}
                        onChange={(e) => console.log(e.target.checked)}
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="customSwitch1"
                        onClick={() => {
                          setIsPresent(!isPresent);
                        }}
                      >
                        Present
                      </Label>
                    </div>
                    {!isPresent && (
                      <FormGroup row>
                        <Label className="col-md-2 col-form-label">
                          Absent Reason
                        </Label>
                        <Col md={10}>
                          <Select
                            options={optionGroup}
                            // className="form-control"
                            // classNamePrefix="select2-selection"
                          />
                        </Col>
                      </FormGroup>
                    )}
                  </Col>

                  {isPresent && questionsData.length !== 0 && column && (
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField="question"
                        data={questionsData}
                        columns={column}
                        cellEdit={cellEditFactory({
                          mode: "click",
                          blurToSave: true,
                          afterSaveCell: (oldValue, newValue, row, column) => {
                            handleUpdate(newValue, row, column);
                          },
                        })}
                        autoSelectText={true}
                      />
                    </div>
                  )}
                </CardBody>
                <CardFooter>
                  <Row>
                    <Button
                      color="primary"
                      className=" waves-effect waves-light mr-1"
                      onClick={() => handleSubmit()}
                      type="button"
                    >
                      Submit
                    </Button>

                    {teacherStudents &&
                      teacherStudents.length > 0 &&
                      teacherStudents[teacherStudents.length - 1]?.StudentID !==
                        stuId && (
                        <Button
                          color="primary"
                          className=" waves-effect waves-light mr-1"
                          onClick={() => handleSubmitAndNext()}
                          type="button"
                        >
                          Submit &amp; Next
                        </Button>
                      )}
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ exam }) => ({
  studentDetails: exam.studentDetails,
  paperStyle: exam.paperStyle,
  teacherStudents: exam.teacherStudents,
});

export default connect(mapStateToProps, {
  getStudentDetail,
  getPaperStyle,
  addStudentMarks,
})(Paper);
