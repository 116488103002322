import {
    SELECT_CLASS_TYPE,
    GET_TEACHER_CLASSES,
    GET_TEACHER_CLASSES_SUCCESS,
    GET_TEACHER_CLASSES_ERROR,
    GET_TEACHER_STUDENTS,
    GET_TEACHER_STUDENTS_SUCCESS,
    GET_TEACHER_STUDENTS_ERROR,
    GET_STUDENT_DETAIL,
    GET_STUDENT_DETAIL_SUCCESS,
    GET_STUDENT_DETAIL_ERROR,
    GET_PAPER_STYLE,
    GET_PAPER_STYLE_SUCCESS,
    GET_PAPER_STYLE_ERROR,
    POST_STUDENT_MARKS,
    POST_STUDENT_MARKS_SUCCESS,
    POST_STUDENT_MARKS_ERROR,
    GET_STUDENT_MARKS,
    GET_STUDENT_MARKS_SUCCESS,
    GET_STUDENT_MARKS_ERROR
} from "../actions";

const initialState = {
    teacherClasses: [],
    teacherStudents: [],
    studentDetails: {},
    paperStyle: {},
    studentMarks: {},
    classType: {},
    isLoading: false,
    isError: false,
};

const fun = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_CLASS_TYPE:
            return {
                ...state,
                classType: action.payload
            };
        case GET_TEACHER_CLASSES:
            return {
                ...state,
                teacherClasses: action.payload,
                isLoading: true,
                isError: false,
            };
        case GET_TEACHER_CLASSES_SUCCESS:
            return {
                ...state,
                teacherClasses: action.payload,
                isLoading: false,
                isError: false,
            };
        case GET_TEACHER_CLASSES_ERROR:
            return {
                ...state,
                teacherClasses: action.payload,
                isLoading: false,
                isError: true,
            };

        case GET_TEACHER_STUDENTS:
            return {
                ...state,
                teacherStudents: action.payload,
                isLoading: true,
                isError: false,
            };
        case GET_TEACHER_STUDENTS_SUCCESS:
            return {
                ...state,
                teacherStudents: action.payload,
                isLoading: false,
                isError: false,
            };
        case GET_TEACHER_STUDENTS_ERROR:
            return {
                ...state,
                teacherStudents: action.payload,
                isLoading: false,
                isError: true,
            };

        case GET_STUDENT_DETAIL:
            return {
                ...state,
                studentDetails: action.payload,
                isLoading: true,
                isError: false,
            };
        case GET_STUDENT_DETAIL_SUCCESS:
            return {
                ...state,
                studentDetails: action.payload,
                isLoading: false,
                isError: false,
            };
        case GET_STUDENT_DETAIL_ERROR:
            return {
                ...state,
                studentDetails: action.payload,
                isLoading: false,
                isError: true,
            };

        case GET_PAPER_STYLE:
            return {
                ...state,
                paperStyle: action.payload,
                isLoading: true,
                isError: false,
            };
        case GET_PAPER_STYLE_SUCCESS:
            return {
                ...state,
                paperStyle: action.payload,
                isLoading: false,
                isError: false,
            };
        case GET_PAPER_STYLE_ERROR:
            return {
                ...state,
                paperStyle: action.payload,
                isLoading: false,
                isError: true,
            };

        case POST_STUDENT_MARKS:
            return {
                ...state,
                studentMarks: action.payload,
                isLoading: true,
                isError: false,
            };
        case POST_STUDENT_MARKS_SUCCESS:
            return {
                ...state,
                studentMarks: action.payload,
                isLoading: false,
                isError: false,
            };
        case POST_STUDENT_MARKS_ERROR:
            return {
                ...state,
                studentMarks: action.payload,
                isLoading: false,
                isError: true,
            };
        
        case GET_STUDENT_MARKS:
            return {
                ...state,
                studentMarks: action.payload,
                isLoading: true,
                isError: false,
            };
        case GET_STUDENT_MARKS_SUCCESS:
            return {
                ...state,
                studentMarks: action.payload,
                isLoading: false,
                isError: false,
            };
        case GET_STUDENT_MARKS_ERROR:
            return {
                ...state,
                studentMarks: action.payload,
                isLoading: false,
                isError: true,
            };

        default:
            return { ...state };
    }
};

export default fun;
