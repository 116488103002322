import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import axios from "axios";

import {
    GET_TEACHER_CLASSES,
    GET_TEACHER_STUDENTS,
    GET_STUDENT_DETAIL,
    GET_PAPER_STYLE,
    POST_STUDENT_MARKS,
    GET_STUDENT_MARKS,
    UPDATE_STUDENT_MARKS
} from "../actions";

import {
    getTeacherClassesSuccess,
    getTeacherClassesError,
    getTeacherStudentsSuccess,
    getTeacherStudentsError,
    getStudentDetailSuccess,
    getStudentDetailError,
    getPaperStyleSuccess,
    getPaperStyleError,
    addStudentMarksSuccess,
    addStudentMarksError,
    getStudentMarksSuccess,
    getStudentMarksError,
    updateStudentMarksSuccess,
    updateStudentMarksError
} from "./action";

import {
    GetTeacherClassesAPI,
    GetTeacherStudentsAPI,
    GetStudentDetailAPI,
    GetPaperStyleAPI,
    PostStudentMarksAPI,
    UpdateStudentMarksAPI,
    GetStudentMarksAPI
} from "../../constants/apiRoutes";
import { setAlert } from "../Alerts/action";


const getTeacherClassesReq = async () => {
    return await axios.get(GetTeacherClassesAPI);
};
const getTeacherStudentsReq = async ({ schId, classId, sectionId }) => {
    return await axios.get(`${GetTeacherStudentsAPI}/${schId}/${classId}/${sectionId}`);
};
const getStudentDetailReq = async (stuId) => {
    return await axios.get(`${GetStudentDetailAPI}/${stuId}`);
};
const getPaperStyleReq = async ({ classId, subject }) => {
    return await axios.get(`${GetPaperStyleAPI}/${classId}/${subject}`);
};

const PostStudentMarksReq = async (payload) => {
    return await axios.post(`${PostStudentMarksAPI}`, payload);
};
const getStudentMarksReq = async (payload) => {
    return await axios.get(`${GetStudentMarksAPI}/${payload.stuId}/${payload.paperId}`);
};
const UpdateStudentMarksReq = async (payload) => {
    return await axios.patch(`${UpdateStudentMarksAPI}/${payload}`, payload);
};

function* getTeacherClassesSaga() {
    try {
        const { data } = yield call(getTeacherClassesReq);
        yield put(getTeacherClassesSuccess(data.data));
    } catch (error) {
        yield put(getTeacherClassesError(error));
        yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
    }
}
function* getTeacherStudentsSaga({ payload }) {
    try {
        const { data } = yield call(getTeacherStudentsReq, payload);
        yield put(getTeacherStudentsSuccess(data.data));
    } catch (error) {
        yield put(getTeacherStudentsError(error));
        yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
    }
}
function* getStudentDetailSaga({ payload }) {
    try {
        const { data } = yield call(getStudentDetailReq, payload);
        yield put(getStudentDetailSuccess(data.data));
    } catch (error) {
        yield put(getStudentDetailError(error));
        yield put(setAlert({ message: error.response.data.message || error.message, type: "ERROR" }));
    }
}

function* getPaperStyleSaga({ payload, stuId }) {
    try {
        const { data } = yield call(getPaperStyleReq, payload);
        const paperStyleData = data.data || false;
        const marks = yield call(getStudentMarksReq, { stuId, paperId: data.data.paperId });
        const studentMarksData = marks.data.data || false;
        if (studentMarksData.marks && studentMarksData.marks.length !== 0) {
            yield put(getPaperStyleSuccess({ ...paperStyleData, questions: studentMarksData.marks, isPresent: studentMarksData.isPresent }));
        } else {
            yield put(getPaperStyleSuccess(paperStyleData));
        }
    } catch (error) {
        yield put(getPaperStyleError(error));
        yield put(setAlert({ message: error.response.data.message || error.message, type: "ERROR" }));
    }
}
function* getStudentMarksSaga({ payload }) {
    try {
        const { data } = yield call(getStudentMarksReq, payload);
        yield put(getStudentMarksSuccess(data.data));
    } catch (error) {
        yield put(getStudentMarksError(error));
        yield put(setAlert({ message: error.response.data.message || error.message, type: "ERROR" }));
    }
}
function* updateStudentMarksSaga({ payload }) {
    try {
        const { data } = yield call(UpdateStudentMarksReq, payload);
        yield put(updateStudentMarksSuccess(data.data));
    } catch (error) {
        yield put(updateStudentMarksError(error));
        yield put(setAlert({ message: error.response.data.message || error.message, type: "ERROR" }));
    }
}
function* postStudentMarksSaga({ payload }) {
    try {
        const { data } = yield call(PostStudentMarksReq, payload);
        yield put(addStudentMarksSuccess(data.data));
    } catch (error) {
        yield put(addStudentMarksError(error));
        yield put(setAlert({ message: error.response.data.message || error.message, type: "ERROR" }));
    }
}

export function* watchGetTeacherClasses() {
    yield takeEvery(GET_TEACHER_CLASSES, getTeacherClassesSaga);
}
export function* watchGetTeacherStudents() {
    yield takeEvery(GET_TEACHER_STUDENTS, getTeacherStudentsSaga);
}
export function* watchGetStudentDetail() {
    yield takeEvery(GET_STUDENT_DETAIL, getStudentDetailSaga);
}
export function* watchGetPaperStyle() {
    yield takeEvery(GET_PAPER_STYLE, getPaperStyleSaga);
}
export function* watchPostStudentMarks() {
    yield takeEvery(POST_STUDENT_MARKS, postStudentMarksSaga);
}
export function* watchGetStudentMarks() {
    yield takeEvery(GET_STUDENT_MARKS, getStudentMarksSaga);
}
export function* watchUpdateStudentMarks() {
    yield takeEvery(UPDATE_STUDENT_MARKS, updateStudentMarksSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetTeacherClasses),
        fork(watchGetTeacherStudents),
        fork(watchGetStudentDetail),
        fork(watchGetPaperStyle),
        fork(watchPostStudentMarks),
        fork(watchGetStudentMarks),
        fork(watchUpdateStudentMarks)
    ]);
}
