import React, { useState } from "react";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";


const Layout = ({ children }) => {
  const [sidebarState, setSidebarState] = useState("condensed");

  const toggleMenu = () => {
    setSidebarState(sidebarState === "condensed" ? "other" : "condensed")
  }

  return (
    <React.Fragment>
      {/* <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div> */}


      <div id="layout-wrapper">
        <Header toggleMenu={toggleMenu} />
        <Sidebar type={sidebarState} />
        <div className="main-content">
          <>
            {children}
          </>
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Layout;

