import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// users
import avatar2 from '../../../assets/images/users/dummy.jpg';
import { connect } from 'react-redux';

const ProfileMenu = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggle = () => { setIsMenuOpen(prevState => (!prevState)) };

    const user = JSON.parse(localStorage.getItem("authUser"));
    console.log(user)
    // let username = "";
    // if (user) {
    //     const uNm = user?.username?.split("@")[0];
    //     username = uNm?.charAt(0).toUpperCase() + uNm?.slice(1);
    // }

    return (
        <>
            <Dropdown isOpen={isMenuOpen} toggle={toggle} className="d-inline-block user-dropdown">
                <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
                    <img className="rounded-circle header-profile-user mr-1" src={avatar2} alt="Header Avatar" />

                    <span className="d-none d-xl-inline-block ml-1 text-transform">{user.name || ""}</span>

                    <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem>{user.name}</DropdownItem>
                    {/* <DropdownItem>{user.role}</DropdownItem> */}
                    {/* <Link to="/profile"><DropdownItem><i className="ri-user-line align-middle mr-1"></i>Profile</DropdownItem></Link> */}
                    {/* <DropdownItem href="#"><i className="ri-wallet-2-line align-middle mr-1"></i>My Wallet</DropdownItem> */}
                    {/* <DropdownItem className="d-block" href="#"><span className="badge badge-success float-right mt-1">11</span><i className="ri-settings-2-line align-middle mr-1"></i>Settings</DropdownItem> */}
                    {/* <DropdownItem href="#"><i className="ri-lock-unlock-line align-middle mr-1"></i> Lock screen</DropdownItem> */}
                    <DropdownItem divider />
                    <DropdownItem className="text-danger" href="/logout"><i className="ri-shut-down-line align-middle mr-1 text-danger"></i> Logout</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </>
    );

}

const mapStateToProps = ({ auth }) => ({
    user: auth.user
});

export default connect(mapStateToProps, null)(ProfileMenu);
