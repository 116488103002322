import React, { useEffect, useState } from "react";
import { Row, Col, Button, Container, Label, FormGroup } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";

import { Link, useHistory } from "react-router-dom";
// import images
import { registerUser } from "../../redux/auth/actions";
import { connect } from "react-redux";
import { sha256 } from "js-sha256";

const Register = ({ registerUser, isLoading }) => {

    const [data, setData] = useState({
        email: "",
        password: "",
        role: { label: "Teacher", value: "Teacher" },
        name: ""
    })

    const optionGroup = [
        {
            label: "Role",
            options: [
                { label: "Admin", value: "Admin" },
                { label: "School", value: "School" },
                { label: "Teacher", value: "Teacher" }
            ]
        }
    ];

    const history = useHistory();
    const handleSubmit = () => {
        const hashedPassword = sha256.hmac('$2a$10$QfokjyvYg/5796GLLI338uOhOhNXqwr9J4iOSK3NR4TLmw7E/6oIS', data.password);
        const formattedRole = data.role.value;
        registerUser({ ...data, password: hashedPassword, role: formattedRole }, history)
    }
    const handleChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;
        setData({ ...data, [name]: val });
    }

    useEffect(() => {
        document.body.classList.add("auth-body-bg");
    }, []);

    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/"><i className="mdi mdi-home-variant h2 text-white"></i></Link>
            </div>
            <div>
                <Container fluid className="p-0">
                    <Row className="no-gutters">
                        <Col lg={4}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        <Link to="#" className="logo">
                                                            {/* <img src={logodark} height="20" alt="logo" /> */}
                                                            DBSE MIS
                                                        </Link>
                                                    </div>

                                                    <h4 className="font-size-18 mt-4">Register account</h4>
                                                    <p className="text-muted">Get your Directorate of Education account now.</p>
                                                </div>

                                                <div className="p-2 mt-5">
                                                    <AvForm onValidSubmit={handleSubmit} className="form-horizontal" >

                                                        <FormGroup className="auth-form-group-custom mb-4">
                                                            <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="email">Email</Label>
                                                            <AvField name="email" value={data.email} onChange={handleChange} validate={{ email: true, required: true }} type="email" className="form-control" id="email" placeholder="Enter email" />
                                                        </FormGroup>

                                                        <FormGroup className="auth-form-group-custom mb-4">
                                                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="userpassword">Password</Label>
                                                            <AvField name="password" value={data.password} onChange={handleChange} type="password" className="form-control" id="userpassword" placeholder="Enter password" />
                                                        </FormGroup>
                                                        
                                                        <FormGroup className="auth-form-group-custom mb-4">
                                                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="userName">Name</Label>
                                                            <AvField name="name" value={data.name} onChange={handleChange} type="text" className="form-control" id="username" placeholder="Enter Name" />
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                            <Label for="basicpill-email-input17">Role</Label>
                                                            <Select options={optionGroup} classNamePrefix="select2-selection" defaultValue={data.role} onChange={(e) => setData({ ...data, role: e })} name="role" />
                                                        </FormGroup>

                                                        <div className="text-center">
                                                            <Button color="primary" disabled={isLoading} className="w-md waves-effect waves-light" type="submit">Register</Button>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <p className="mb-0">By registering you agree to the EDUMIS-DBSE<Link to="#" className="text-primary">Terms of Use</Link></p>
                                                        </div>
                                                    </AvForm>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p>Already have an account ? <Link to="/login" className="font-weight-medium text-primary"> Login</Link> </p>
                                                    <p>© 2022 Delhi Board of School Education.</p>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="authentication-bg">
                                <div className="bg-overlay"></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    );
}

const mapStateToProps = ({ auth }) => ({
    isLoading: auth.loading
})
export default connect(mapStateToProps, { registerUser })(Register);
