import {
    USER_LOGIN,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_ERROR,
    USER_LOGOUT,
    USER_LOGOUT_SUCCESS,
    USER_LOGOUT_ERROR,
    USER_REGISTER,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_ERROR
} from "../actions";

import { getLocal } from "../../helpers/localstorage";

const initialState = {
    token: getLocal("token") ? getLocal("token") : "",
    error: false,
    loading: false,
    isAuth: getLocal("token") ? true : false,
    user: getLocal("authUser") ? JSON.parse(getLocal("authUser")) : {},
    role: getLocal("authUser") ? JSON.parse(getLocal("authUser")).role : "",
};

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_REGISTER:
            return {
                ...state,
                error: false,
                loading: true
            };

        case USER_REGISTER_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false
            };
        case USER_REGISTER_ERROR:
            return {
                ...state,
                error: true,
                loading: false
            };

        case USER_LOGIN:
            return {
                ...state,
                error: false,
                loading: true,
                user: {},
                role: "",
                token: "",
            };

        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                token: action.payload.data.token,
                role: action.payload.data.role,
                user: action.payload.data,
                isAuth: true,
                loading: false,
                error: false,
            };
        case USER_LOGIN_ERROR:
            return {
                ...state,
                token: "",
                user: {},
                role: "",
                loading: false,
                isAuth: false,
                error: true,
            };

        case USER_LOGOUT:
            return {
                ...state,
                error: false,
                loading: true,
                user: {},
                role: "",
                token: "",
                isAuth: false
            };

        case USER_LOGOUT_SUCCESS:
            return {
                ...state,
                token: "",
                role: "",
                user: {},
                loading: false,
                error: false,
                isAuth: false
            };
        case USER_LOGOUT_ERROR:
            return {
                ...state,
                token: "",
                user: {},
                role: "",
                loading: false,
                error: true,
                isAuth: false
            };

        default:
            return { ...state };
    }
}