import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import axios from "axios";

import {
    USER_LOGIN,
    USER_LOGOUT,
    USER_REGISTER
} from "../actions";

import setDefaultToken, { setLocal, clearLocal } from "../../helpers/localstorage";

import {
    userLoginError,
    userLoginSuccess,
    userLogoutSuccess,
    userLogoutError,
    registerUserSuccess,
    registerUserError
} from "./actions";

import { LoginUserAPI, RegisterUserAPI } from "../../constants/apiRoutes";
import { setAlert } from "../Alerts/action";

const userLoginAPI = async data => {
    return axios.post(LoginUserAPI, data);
};
const userRegisterAPI = async data => {
    return axios.post(RegisterUserAPI, data);
};

function* userRegisterSaga({ payload, history }) {
    try {
        const { data } = yield call(userRegisterAPI, { ...payload });
        yield put(registerUserSuccess(data));
        yield history.push("/login");
    } catch (error) {
        yield put(registerUserError(error));
        yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
    }
}

function* userLoginSaga({ payload, history }) {
    try {
        const { data } = yield call(userLoginAPI, { ...payload });
        const { id, name, role, username, token, onboard } = data.data
        setDefaultToken("Authorization", token);
        setLocal("token", token);
        setLocal("authUser", JSON.stringify({ id, name, role, username, onboard }));
        yield put(userLoginSuccess(data));
        // yield put(setAlert({ message: data.message, type: "SUCCESS" }));
        yield history.push("/dashboard/classes");
    } catch (error) {
        yield put(userLoginError(error));
        yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
    }
}

function* userLogoutSaga({ history }) {
    try {
        setDefaultToken("Authorization", "");
        clearLocal();
        yield put(userLogoutSuccess());
        yield history.push("/");
        yield put(setAlert({ message: "Logged Out", type: "SUCCESS" }));
    } catch (error) {
        yield history.push("/dashboard/classes");
        yield put(userLogoutError(error));
        yield put(setAlert({ message: "Error Logging Out", type: "ERROR" }));
    }
}

export function* watchUserRegister() {
    yield takeEvery(USER_REGISTER, userRegisterSaga);
}
export function* watchUserLogin() {
    yield takeEvery(USER_LOGIN, userLoginSaga);
}
export function* watchUserLogout() {
    yield takeEvery(USER_LOGOUT, userLogoutSaga);
}

export default function* rootSaga() {
    yield all([fork(watchUserLogin), fork(watchUserLogout), fork(watchUserRegister)]);
}
