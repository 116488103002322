import {
    SELECT_CLASS_TYPE,
    GET_TEACHER_CLASSES,
    GET_TEACHER_CLASSES_SUCCESS,
    GET_TEACHER_CLASSES_ERROR,
    GET_TEACHER_STUDENTS,
    GET_TEACHER_STUDENTS_SUCCESS,
    GET_TEACHER_STUDENTS_ERROR,
    GET_STUDENT_DETAIL,
    GET_STUDENT_DETAIL_SUCCESS,
    GET_STUDENT_DETAIL_ERROR,
    GET_PAPER_STYLE,
    GET_PAPER_STYLE_SUCCESS,
    GET_PAPER_STYLE_ERROR,
    POST_STUDENT_MARKS,
    POST_STUDENT_MARKS_SUCCESS,
    POST_STUDENT_MARKS_ERROR,
    GET_STUDENT_MARKS,
    GET_STUDENT_MARKS_SUCCESS,
    GET_STUDENT_MARKS_ERROR,
    UPDATE_STUDENT_MARKS,
    UPDATE_STUDENT_MARKS_SUCCESS,
    UPDATE_STUDENT_MARKS_ERROR,
} from "../actions";

export const selectClassType = payload => {
    return {
        type: SELECT_CLASS_TYPE,
        payload
    }
};

export const getTeacherClasses = payload => {
    return {
        type: GET_TEACHER_CLASSES,
        payload
    }
};
export const getTeacherClassesSuccess = payload => {
    return {
        type: GET_TEACHER_CLASSES_SUCCESS,
        payload
    }
};
export const getTeacherClassesError = payload => {
    return {
        type: GET_TEACHER_CLASSES_ERROR,
        payload
    }
};

export const getTeacherStudents = payload => {
    return {
        type: GET_TEACHER_STUDENTS,
        payload
    }
};
export const getTeacherStudentsSuccess = payload => {
    return {
        type: GET_TEACHER_STUDENTS_SUCCESS,
        payload
    }
};
export const getTeacherStudentsError = payload => {
    return {
        type: GET_TEACHER_STUDENTS_ERROR,
        payload
    }
};

export const getStudentDetail = payload => {
    return {
        type: GET_STUDENT_DETAIL,
        payload
    }
};
export const getStudentDetailSuccess = payload => {
    return {
        type: GET_STUDENT_DETAIL_SUCCESS,
        payload
    }
};
export const getStudentDetailError = payload => {
    return {
        type: GET_STUDENT_DETAIL_ERROR,
        payload
    }
};

export const getPaperStyle = (payload, stuId) => {
    return {
        type: GET_PAPER_STYLE,
        payload,
        stuId
    }
};
export const getPaperStyleSuccess = payload => {
    return {
        type: GET_PAPER_STYLE_SUCCESS,
        payload
    }
};
export const getPaperStyleError = payload => {
    return {
        type: GET_PAPER_STYLE_ERROR,
        payload
    }
};

export const addStudentMarks = payload => {
    return {
        type: POST_STUDENT_MARKS,
        payload
    }
};
export const addStudentMarksSuccess = payload => {
    return {
        type: POST_STUDENT_MARKS_SUCCESS,
        payload
    }
};
export const addStudentMarksError = payload => {
    return {
        type: POST_STUDENT_MARKS_ERROR,
        payload
    }
};

export const getStudentMarks = (payload) => {
    return {
        type: GET_STUDENT_MARKS,
        payload
    }
};
export const getStudentMarksSuccess = payload => {
    return {
        type: GET_STUDENT_MARKS_SUCCESS,
        payload
    }
};
export const getStudentMarksError = payload => {
    return {
        type: GET_STUDENT_MARKS_ERROR,
        payload
    }
};

export const updateStudentMarks = payload => {
    return {
        type: UPDATE_STUDENT_MARKS,
        payload
    }
};
export const updateStudentMarksSuccess = payload => {
    return {
        type: UPDATE_STUDENT_MARKS_SUCCESS,
        payload
    }
};
export const updateStudentMarksError = payload => {
    return {
        type: UPDATE_STUDENT_MARKS_ERROR,
        payload
    }
};
