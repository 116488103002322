import React from "react";
import {
  Card,
  CardBody,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

const Table = ({ teacherStats }) => {

  function verifiedFormatter(cell, row) {
    if (cell) {
      return (
        <span><strong className="text-success">Verified</strong></span>
        );
      }
      return (
      <span><strong className="text-danger">Pending</strong></span>
    );
  }
  
  const data = {
    columns: [
      {
        dataField: "schoolId",
        text: "School ID",
      },
      {
        dataField: "teacherId",
        text: "Teacher Id",
      },
      {
        dataField: "subject",
        text: "Subject",
      },
      {
        dataField: "classId",
        text: "Grade",
      },
      {
        dataField: "section",
        text: "Section",
      },
      {
        dataField: "submitted",
        text: "Total Submitted",
      },
      {
        dataField: "isVerified",
        text: "Status",
        formatter: verifiedFormatter
      }
    ],
    rows: teacherStats
  };

  const options = {
    // pageStartIndex: 0,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    sizePerPageList: [
      {
        text: "5th",
        value: 5,
      },
      {
        text: "10th",
        value: 10,
      },
      {
        text: "All",
        value: data?.rows.length,
      },
    ],
  };

  // const selectRow = {
  //   mode: "checkbox",
  //   clickToSelect: true,
  // };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {teacherStats && teacherStats.length > 0 && (
            <BootstrapTable
              keyField="id"
              data={data.rows}
              columns={data.columns}
              pagination={paginationFactory(options)}
              // selectRow={selectRow}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default Table;
