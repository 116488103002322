import {
    POST_USER_DETAILS,
    POST_USER_DETAILS_SUCCESS,
    POST_USER_DETAILS_ERROR,
    GET_USER_DETAILS,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_ERROR,
    GET_TEACHER_STATS,
    GET_TEACHER_STATS_SUCCESS,
    GET_TEACHER_STATS_ERROR,
    GET_DASHBOARD_STATS,
    GET_DASHBOARD_STATS_SUCCESS,
    GET_DASHBOARD_STATS_ERROR,
    MARK_CLASS_STATUS_VERIFIED,
    MARK_CLASS_STATUS_VERIFIED_SUCCESS,
    MARK_CLASS_STATUS_VERIFIED_ERROR
} from "../actions";

const initialState = {
    userDetails: {},
    dashboardStats: {},
    teacherStats: [],
    error: false,
    loading: false
};

export default (state = initialState, action) => {
    switch (action.type) {

        case POST_USER_DETAILS:
            return {
                ...state,
                error: false,
                loading: true
            };

        case POST_USER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false
            };
        case POST_USER_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            };

        case GET_USER_DETAILS:
            return {
                ...state,
                error: false,
                loading: true,
                userDetails: []
            };
        case GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                userDetails: action.payload.data
            };
        case GET_USER_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                userDetails: []
            };

        case GET_TEACHER_STATS:
            return {
                ...state,
                error: false,
                loading: true,
                teacherStats: []
            };
        case GET_TEACHER_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                teacherStats: action.payload.data
            };
        case GET_TEACHER_STATS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                teacherStats: []
            };

        case GET_DASHBOARD_STATS:
            return {
                ...state,
                error: false,
                loading: true,
                dashboardStats: {}
            };
        case GET_DASHBOARD_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                dashboardStats: action.payload.data
            };
        case GET_DASHBOARD_STATS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                dashboardStats: {}
            };

        case MARK_CLASS_STATUS_VERIFIED:
            return {
                ...state,
                error: false,
                loading: true
            };
        case MARK_CLASS_STATUS_VERIFIED_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false
            };
        case MARK_CLASS_STATUS_VERIFIED_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            };

        default:
            return { ...state };
    }
}