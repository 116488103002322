import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, CardBody, Container, Media, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import { getTeacherClasses, selectClassType } from '../../../../redux/Exam/action';

const Assesments = ({ selectClassType, getTeacherClasses, teacherClasses }) => {
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false)
    const [selectedSubject, setSelectedSubject] = useState('All')
    const [teacherSubjects, setTeacherSubjects] = useState([]);
    const [classesData, setClassesData] = useState([])

    useEffect(() => {
        getTeacherClasses();
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (teacherClasses && teacherClasses.length !== 0) {
            setClassesData(teacherClasses)

            let totalTeacherSubjects = []
            // eslint-disable-next-line
            teacherClasses.map((item) => {
                totalTeacherSubjects.push(item.Subject)
            })
            let totalSubjects = totalTeacherSubjects.filter((x, i, a) => a.indexOf(x) === i)
            totalSubjects = ['All', ...totalSubjects]
            setTeacherSubjects(totalSubjects)
        }
    }, [teacherClasses])


    const breadcrumbItems = [
        { title: "Home", link: "/dashboard" },
        { title: "Assessments", link: "#" }
    ];


    const handleClick = (payload) => {
        selectClassType(payload);
        // :schId/:classId/:sectionId/:subject
        history.push(`/dashboard/students/${payload.SchoolID}/${payload.Grade}/${payload.Section}/${payload.Subject}`)
    }


    const handleCardFilter = (item) => {
        setSelectedSubject(item)
        if (item.toLowerCase() !== 'all') {
            let filteredData = teacherClasses.filter(e => e.totalSubjects.includes(item));
            console.log(filteredData)
            // eslint-disable-next-line
            filteredData.map((item2) => {
                item2.totalSubjects = [item]
            })
            setClassesData(filteredData);
        } else {
            setClassesData(teacherClasses)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Assesments"
                        breadcrumbItems={breadcrumbItems}
                        showBtn={false}
                    />

                    <Row>
                        <Col xs={12}>
                            <div style={{ display: 'flex', justifyContent: "space-between", marginBottom: '1rem' }}>
                                <h3 className="mb-3">Select your Class</h3>
                                {teacherSubjects && teacherSubjects.length > 2 && (
                                    <Row>
                                        <Col xl={12} style={{ display: 'flex', marginRight: '2rem', alignItems: 'center' }}>
                                            <h3 className="mr-3">Select your Subject</h3>
                                            <Dropdown
                                                isOpen={isOpen}
                                                toggle={() =>
                                                    setIsOpen(!isOpen)
                                                }
                                            >
                                                <DropdownToggle color="primary" caret>
                                                    {selectedSubject}
                                                    <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {teacherSubjects.length > 0 && teacherSubjects.map((item, key) =>
                                                        <DropdownItem key={key} onClick={() => handleCardFilter(item)}>{item}</DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                )}
                            </div>

                            <Row>
                                {
                                    teacherSubjects && teacherSubjects.length > 2 && (
                                        < div className='mb-3' style={{ display: "flex" }} >
                                            <p className="text-muted mb-0 mr-4"><i style={{ color: "#4F4789" }} className="mdi mdi-circle align-middle mr-1"></i> Maths</p>
                                            <p className="text-muted mb-0 mr-4"><i style={{ color: "#FCE762" }} className="mdi mdi-circle align-middle mr-1"></i> Science</p>
                                            <p className="text-muted mb-0 mr-4"><i style={{ color: "#FFB17A" }} className="mdi mdi-circle align-middle mr-1"></i> SST</p>
                                            <p className="text-muted mb-0 mr-4"><i style={{ color: "#8193B6" }} className="mdi mdi-circle align-middle mr-1"></i> English</p>
                                            <p className="text-muted mb-0 mr-4"><i style={{ color: "#E27396" }} className="mdi mdi-circle align-middle mr-1"></i> Hindi</p>
                                        </div>

                                    )
                                }
                            </Row>
                            <Row>
                                {classesData && classesData.map((item, key) => (
                                    // item.totalSubjects.length >= 1 && item.totalSubjects.map((subject, key) => {
                                        <Col xl={3} lg={4} md={4} sm={6} key={key}>
                                            <Card style={{ cursor: "pointer" }} onClick={() => handleClick(item)}>
                                                <CardBody>
                                                    <Media>
                                                        <Media body className="overflow-hidden">
                                                            <p className="text-truncate font-size-14 mb-2">Class</p>
                                                            <h4 className="mb-0">{item.Grade} {item.Section}</h4>
                                                        </Media>
                                                        <span className="badge ml-2 font-size-14 mr-1 p-2 badge-soft-dark" >
                                                            {item.Subject}
                                                        </span>
                                                    </Media>
                                                </CardBody>

                                                <CardBody
                                                    className="border-top py-3"
                                                    style={{
                                                        // backgroundColor: `${subject === "Maths" ? "#0033CC" : subject === "English" ? "#009933" : subject === "Hindi" ? "#FF0066" : subject === "Science" ? "#FFCC33" : "#FF6600"}`
                                                        backgroundColor: `${item.Subject === "Maths" ? "#4F4789" : item.Subject === "English" ? "#8193B6" : item.Subject === "Hindi" ? "#E27396" : item.Subject === "Science" ? "#FCE762" : "#FFB17A"}`
                                                    }}
                                                >
                                                    <div className="text-truncate">
                                                        <span className="text-white">Total Students</span>
                                                        <span className="badge ml-2 badge-soft-light font-size-16 font-weight-2 mr-1 p-2">{item.students || 34}</span>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    // })
                                ))}
                            </Row>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment >
    )
}

const mapStateToProps = ({ exam }) => ({
    teacherClasses: exam.teacherClasses
})
export default connect(mapStateToProps, { selectClassType, getTeacherClasses })(Assesments);