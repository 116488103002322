import React from "react";
import { Col, Row } from "reactstrap";
import Editable from "react-bootstrap-editable";
import "./style.scss";
import moment from "moment";

const Tab1 = ({ userDetails }) => {
  return (
    <div>
      <Row className="card-row">
        <Col md="4">First Name</Col>
        <Col md="8">
          <Editable
            alwaysEditing={false}
            disabled={false}
            editText="edit"
            id={null}
            initialValue={userDetails.firstName}
            isValueClickable={false}
            mode="inline"
            placement="top"
            showText
            type="textfield"
          />
        </Col>
      </Row>
      <Row className="card-row">
        <Col md="4">Last Name</Col>
        <Col md="8">
          <Editable
            alwaysEditing={false}
            disabled={false}
            editText="edit"
            id={null}
            initialValue={userDetails.lastName}
            isValueClickable={false}
            mode="inline"
            placement="top"
            showText
            type="textfield"
          />
        </Col>
      </Row>
      <Row className="card-row">
        <Col md="4">Father's Name</Col>
        <Col md="8">
          <Editable
            alwaysEditing={false}
            disabled={false}
            editText="edit"
            id={null}
            initialValue={userDetails.fathersName}
            isValueClickable={false}
            mode="inline"
            placement="top"
            showText
            type="textfield"
          />
        </Col>
      </Row>
      <Row className="card-row">
        <Col md="4">Phone</Col>
        <Col md="8">
          <Editable
            alwaysEditing={false}
            disabled={false}
            editText="edit"
            id={null}
            initialValue={userDetails.phone}
            isValueClickable={false}
            mode="inline"
            placement="top"
            showText
            type="textfield"
          />
        </Col>
      </Row>
      <Row className="card-row">
        <Col md="4">Tel</Col>
        <Col md="8">
          <Editable
            alwaysEditing={false}
            disabled={false}
            editText="edit"
            id={null}
            initialValue={userDetails.tel || "-"}
            isValueClickable={false}
            mode="inline"
            placement="top"
            showText
            type="textfield"
          />
        </Col>
      </Row>
      <Row className="card-row">
        <Col md="4">Email</Col>
        <Col md="8">
          <Editable
            alwaysEditing={false}
            disabled={false}
            editText="edit"
            id={null}
            initialValue={userDetails.email}
            isValueClickable={false}
            mode="inline"
            placement="top"
            showText
            type="textfield"
          />
        </Col>
      </Row>
      <Row className="card-row">
        <Col md="4">Gender</Col>
        <Col md="8">
          <Editable
            alwaysEditing={false}
            disabled={false}
            editText="edit"
            id={null}
            initialValue={userDetails.gender}
            options={[
              'Male',
              'Female'
            ]}    
            isValueClickable={false}
            mode="inline"
            placement="top"
            showText
            type="select"
          />
        </Col>
      </Row>
      <Row className="card-row">
        <Col md="4">DOB</Col>
        <Col md="8">
          <Editable
            alwaysEditing={false}
            disabled={false}
            editText="edit"
            id={null}
            initialValue={moment(userDetails.dob).format("MM/DD/YY")}
            isValueClickable={false}
            mode="inline"
            placement="top"
            showText
            type="textfield"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Tab1;
