import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Button, Container, Label, FormGroup } from "reactstrap";

// Redux
import { withRouter, Link, useHistory } from 'react-router-dom';

import { AvForm, AvField } from 'availity-reactstrap-validation';

// import images
import { userLogin } from '../../redux/auth/actions';
import { connect } from 'react-redux';
import { clearLocal } from '../../helpers/localstorage';
import { sha256 } from 'js-sha256';

const Login = ({ userLogin, isLoading }) => {

    const history = useHistory();
    const [data, setData] = useState({
        email: "",
        password: ""
    });
    
    useEffect(() => {
        clearLocal(); // Clear Local Storage
    }, []);
    
    const handleChange = (e) => {
        const val = e.target.value
        const name = e.target.name
        setData({ ...data, [name]: val })
    }
    
    const handleSubmit = () => {
    const hashedPassword = sha256.hmac('$2a$10$QfokjyvYg/5796GLLI338uOhOhNXqwr9J4iOSK3NR4TLmw7E/6oIS', data.password);
        setData({...data, password: hashedPassword})
        userLogin({...data, password: hashedPassword}, history)
        // userLogin(data, history);
        setTimeout(() => {
            setData({...data, password: ""})
        }, 3000)
    }

    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/"><i className="mdi mdi-home-variant h2 text-white"></i></Link>
            </div>

            <div>
                <Container fluid className="p-0">
                    <Row className="no-gutters">
                        <Col lg={4}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        <Link to="/" className="logo">
                                                            {/* <img src={logodark} height="20" alt="logo" /> */}
                                                            DBSE MIS
                                                        </Link>
                                                    </div>

                                                    <h4 className="font-size-18 mt-4">Welcome Back !</h4>
                                                    <p className="text-muted">Sign in to continue to EDUMIS-DBSE.</p>
                                                </div>

                                                <div className="p-2 mt-5">
                                                    <AvForm className="form-horizontal" onValidSubmit={handleSubmit} >

                                                        <FormGroup className="auth-form-group-custom mb-4">
                                                            <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="email">Employee ID/Email</Label>
                                                            <AvField name="email" onChange={handleChange} value={data.email} type="text" className="form-control" id="email" validate={{ required: true }} placeholder="Enter Employee ID or Email" />
                                                        </FormGroup>

                                                        <FormGroup className="auth-form-group-custom mb-4">
                                                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="userpassword">Password</Label>
                                                            <AvField name="password" onChange={handleChange} value={data.password} type="password" className="form-control" id="userpassword" validate={{ required: true }} placeholder="Enter password" />
                                                        </FormGroup>

                                                        <div className="custom-control custom-checkbox">
                                                            <Input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                            <Label className="custom-control-label" htmlFor="customControlInline">Remember me</Label>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <Button color="primary" disabled={isLoading} className="w-md waves-effect waves-light" type="submit">Log In</Button>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                                        </div>
                                                    </AvForm>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    {/* <p>Don't have an account ? <Link to="/register" className="font-weight-medium text-primary"> Register </Link> </p> */}
                                                    <p>© 2022 Delhi Board of School Education.</p>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="authentication-bg">
                                <div className="bg-overlay"></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );

}


const mapStateToProps = ({ auth }) => ({
    isLoading: auth.loading,
});

export default withRouter(connect(mapStateToProps, { userLogin })(Login));

