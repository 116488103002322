import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import axios from "axios";

import {
    POST_USER_DETAILS,
    GET_USER_DETAILS,
    GET_TEACHER_STATS,
    GET_DASHBOARD_STATS,
    MARK_CLASS_STATUS_VERIFIED
} from "../actions";

import {
    addTeacherDataSuccess,
    addTeacherDataError,
    getUserDetailsSuccess,
    getUserDetailsError,
    getTeacherStatsSuccess,
    getTeacherStatsError,
    getDashboardStatsSuccess,
    getDashboardStatsError,
    markClassVerifiedSuccess,
    markClassVerifiedError
} from "./action";

import {
    AddStep1DetailsAPI,
    GetUserProfileDetailsAPI,
    GetTeacherStatsAPI,
    GetDashboardStatsAPI,
    VerifyClassStatusAPI
} from "../../constants/apiRoutes";
import { setAlert } from "../Alerts/action";


const addTeacherDetail = async (data) => {
    return axios.post(AddStep1DetailsAPI, data);
};

const getUserProfileDetails = async () => {
    return axios.get(GetUserProfileDetailsAPI);
};
const getTeacherStatsApi = async () => {
    return axios.get(GetTeacherStatsAPI);
};
const getDashboardStatsApi = async () => {
    return axios.get(GetDashboardStatsAPI);
};
const verifyClassStatusApi = async ({ schId, classId, section, subject }) => {
    return axios.get(`${VerifyClassStatusAPI}/${schId}/${classId}/${section}/${subject}`);
};

function* adTeacherDetailSaga({ payload }) {
    try {
        const { data } = yield call(addTeacherDetail, payload);
        yield put(addTeacherDataSuccess(data));
        yield put(setAlert({ message: data.message, type: "Success" }));
    } catch (error) {
        yield put(addTeacherDataError(error));
        yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
    }
}
function* getUserProfileDetailSaga() {
    try {
        const { data } = yield call(getUserProfileDetails);
        yield put(getUserDetailsSuccess(data));
    } catch (error) {
        yield put(getUserDetailsError(error));
        yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
    }
}
function* getTeacherStatsSaga() {
    try {
        const { data } = yield call(getTeacherStatsApi);
        yield put(getTeacherStatsSuccess(data));
    } catch (error) {
        yield put(getTeacherStatsError(error));
        yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
    }
}
function* getDashboardStatsSaga() {
    try {
        const { data } = yield call(getDashboardStatsApi);
        yield put(getDashboardStatsSuccess(data));
    } catch (error) {
        yield put(getDashboardStatsError(error));
        yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
    }
}
function* markClassStatusSaga({ payload }) {
    try {
        const { data } = yield call(verifyClassStatusApi, payload);
        yield put(markClassVerifiedSuccess(data));
    } catch (error) {
        yield put(markClassVerifiedError(error));
        yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
    }
}

export function* watchAddTeacherDetail() {
    yield takeEvery(POST_USER_DETAILS, adTeacherDetailSaga);
}
export function* watchGetUserProfileDetail() {
    yield takeEvery(GET_USER_DETAILS, getUserProfileDetailSaga);
}
export function* watchGetTeacherStats() {
    yield takeEvery(GET_TEACHER_STATS, getTeacherStatsSaga);
}
export function* watchGetDashboardStats() {
    yield takeEvery(GET_DASHBOARD_STATS, getDashboardStatsSaga);
}
export function* watchMarkClassStatus() {
    yield takeEvery(MARK_CLASS_STATUS_VERIFIED, markClassStatusSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchAddTeacherDetail),
        fork(watchGetUserProfileDetail),
        fork(watchGetTeacherStats),
        fork(watchGetDashboardStats),
        fork(watchMarkClassStatus),
    ]);
}
