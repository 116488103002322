import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Media,
  Row,
} from "reactstrap";
import { getDashboardStats, getTeacherStats } from "../../../../redux/Main/action";

import Table from "./Table";

const Stats = ({ getTeacherStats, teacherStats, getDashboardStats, dashboardStats }) => {

  useEffect(() => {
    getTeacherStats();
    getDashboardStats()
    // eslint-disable-next-line
  }, [])

  const [filterTeacher, setFilterTeacher] = useState([]);
  const [dashboardCount, setDashboardCount] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTeacher, setSearchTeacher] = useState("");

  useEffect(() => {
    if (teacherStats) {
      setFilterTeacher(teacherStats)
    }
  }, [teacherStats]);

  useEffect(() => {
    if (dashboardStats) {
      setDashboardCount(dashboardStats)
    }
  }, [dashboardStats]);

  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val)
    const filtered = teacherStats.filter((item) => item.schoolId.toLowerCase().includes(val))
    setFilterTeacher(filtered)
  }
  const handleSearchTeacher = (e) => {
    let val = e.target.value;
    setSearchTeacher(val)
    const filtered = teacherStats.filter((item) => item.teacherId.toLowerCase().includes(val))
    setFilterTeacher(filtered)
  }
  const handleClassFilter = (e) => {
    let val = e;
    let arr = new Set()
    val.forEach((item) => {
      arr.add(item.value)
    })
    const filtered = teacherStats.filter((item) => arr.has(item.classId))
    setFilterTeacher(filtered)
  }
  const handleSectionFilter = (e) => {
    let val = e;
    let arr = new Set()
    val.forEach((item) => {
      arr.add(item.value)
    })
    const filtered = teacherStats.filter((item) => arr.has(item.section))
    setFilterTeacher(filtered)
  }
  const handleSubjectFilter = (e) => {
    let val = e;
    let arr = new Set()
    val.forEach((item) => {
      arr.add(item.value)
    })
    const filtered = teacherStats.filter((item) => arr.has(item.subject))
    setFilterTeacher(filtered)
  }
  const handleStatusFilter = (e) => {
    let val = e.value;
    const filtered = teacherStats.filter((item) => val ? item.isVerified !== null : item.isVerified === null)
    setFilterTeacher(filtered)
  }

  const classGroup = [
    {
      options: [
        { label: "Class 1", value: "1" },
        { label: "Class 2", value: "2" },
        { label: "Class 3", value: "3" },
        { label: "Class 4", value: "4" },
        { label: "Class 5", value: "5" },
        { label: "Class 6", value: "6" },
        { label: "Class 7", value: "7" },
        { label: "Class 8", value: "8" },
        { label: "Class 9", value: "9" },
        { label: "Class 10", value: "10" },
        { label: "Class 11", value: "11" },
        { label: "Class 12", value: "12" },
      ],
    },
  ];

  const sectionGroup = [
    {
      options: [
        { label: "A", value: "A" },
        { label: "B", value: "B" },
        { label: "C", value: "C" },
        { label: "D", value: "D" },
        { label: "E", value: "E" },
      ],
    },
  ];
  const subjectGroup = [
    {
      options: [
        { label: "English", value: "English" },
        { label: "Hindi", value: "Hindi" },
        { label: "Maths", value: "Maths" },
        { label: "Social Science", value: "SST" },
        { label: "Science", value: "Science" },
        { label: "Physical", value: "Physical" },
      ],
    },
  ];
  const statusGroup = [
    {
      options: [
        {label: "Pending", value: 0},
        {label: "Sucess", value: 1},
      ],
    },
  ];
  // const teacherGroup = [
  //   {
  //     options: [
  //       { label: "Vandana Rustagi", value: "Vandana Rustagi" },
  //       { label: "Ranjeet Singh", value: "Ranjeet Singh" },
  //       { label: "Neetika Pathak", value: "Neetika Pathak" },
  //       { label: "Sudhakar Jha", value: "Sudhakar Jha" },
  //       { label: "Maneeta Sharma", value: "Maneeta Sharma" },
  //       { label: "Shreya Sharma", value: "Shreya Sharma" },
  //       { label: "Manpreet Kaur", value: "Manpreet Kaur" },
  //       { label: "Archana", value: "Archana" },
  //       { label: "Shabnam", value: "Shabnam" },
  //       { label: "Saurabh Srivastava", value: "Saurabh Srivastava" },
  //       { label: "Anil Rana", value: "Anil Rana" },
  //       { label: "Shivam Tripathi", value: "Shivam Tripathi" },
  //       { label: "Gunjan Gulati", value: "Gunjan Gulati" },
  //       { label: "Meena Yadav", value: "Meena Yadav" },
  //       { label: "Manmeet Kaur", value: "Manmeet Kaur" },
  //       { label: "Ripunjai Kumar", value: "Ripunjai Kumar" },
  //     ],
  //   },
  // ];

  const cardsData = [
    { name: "Number of Schools", number: dashboardCount.totalSchools, icon: "ri-building-line" },
    { name: "Number of Teachers", number: dashboardCount.totalTeachers, icon: "ri-building-line" },
    { name: "Number of Students", number: dashboardCount.totalStudents, icon: "ri-building-line" },
  ];

  return (
    <div className="page-content" style={{ minHeight: "80vh" }}>
      <Container fluid>
        <Row>
          <Col md={6}>
            <Form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <Input
                  style={{ backgroundColor: "#fff" }}
                  type="text"
                  className="form-control"
                  placeholder="School ID"
                  value={search}
                  onChange={handleSearch}
                />
                <span className="ri-search-line"></span>
              </div>
            </Form>
          </Col>
          <Col md={6}>
            <Form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <Input
                  style={{ backgroundColor: "#fff" }}
                  type="text"
                  className="form-control"
                  placeholder="Teacher ID"
                  value={searchTeacher}
                  onChange={handleSearchTeacher}
                />
                <span className="ri-search-line"></span>
              </div>
            </Form>
          </Col>
        </Row>
        <Row>
          {cardsData.map((card, i) => (
            <Col md={4} key={i}>
              <Card>
                <CardBody>
                  <Media>
                    <Media body className="overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        {card.name}
                      </p>
                      <h4 className="mb-0">{card.number}</h4>
                    </Media>
                    <div className="text-primary">
                      <i className={card.icon + " font-size-24"}></i>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>

        <Row>
          <Col lg={8}>
          {teacherStats && <Table teacherStats={filterTeacher} />}
          </Col>

          <Col lg={4} className="pl-4 pr-4">
            <Row>
              <FormGroup className="select2-container w-100">
                <Label>Class</Label>
                <Select
                  options={classGroup}
                  classNamePrefix="select2-selection"
                  isMulti={true}
                  onChange={handleClassFilter}
                />
              </FormGroup>
            </Row>

            <Row>
              <FormGroup className="select2-container w-100">
                <Label>Section</Label>
                <Select
                  options={sectionGroup}
                  classNamePrefix="select2-selection"
                  isMulti={true}
                  onChange={handleSectionFilter}
                />
              </FormGroup>
            </Row>

            <Row>
              <FormGroup className="select2-container w-100">
                <Label>Subject</Label>
                <Select
                  options={subjectGroup}
                  classNamePrefix="select2-selection"
                  isMulti={true}
                  onChange={handleSubjectFilter}
                />
              </FormGroup>
            </Row>
            
            <Row>
              <FormGroup className="select2-container w-100">
                <Label>Status</Label>
                <Select
                  options={statusGroup}
                  classNamePrefix="select2-selection"
                  onChange={handleStatusFilter}
                />
              </FormGroup>
            </Row>

            {/* <Row>
              <FormGroup className="select2-container w-100">
                <Label>Teacher</Label>
                <Select
                  options={teacherGroup}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
            </Row> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ main }) => ({
  teacherStats: main.teacherStats,
  dashboardStats: main.dashboardStats
});

export default connect(mapStateToProps, { getTeacherStats, getDashboardStats })(Stats);
