import React, { useState } from "react";

import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
const Report = () => {

    const [isChecked, setIsChecked] = useState(false);
    const breadcrumbItems = [
        { title: "Home", link: "/dashboard" },
        { title: "Report", link: "#" }
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Report"
                        breadcrumbItems={breadcrumbItems}
                        showBtn={false}
                    />

                    <Col md={12}>
                        <Row>
                            <Button color="primary" id="button-class">
                                Download Class Result
                            </Button>
                        </Row>
                        <Row className="mt-5 ml-2 mb-2">
                            <Input className="form-check-input" defaultChecked={isChecked} type="checkbox" id="isAbsent" onChange={() => setIsChecked(!isChecked)} />
                            <Label className="form-check-label" htmlFor="isAbsent">You have review the result and verified it.</Label>
                        </Row>
                        <Row>
                            <Button color="danger" disabled={!isChecked}>
                                I have reviewed
                            </Button>
                        </Row>
                    </Col>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default Report;