import { uploadFile } from 'react-s3';

const S3_BUCKET = 'doe-sports-uploads';
const REGION = 'ap-south-1';
const ACCESS_KEY = 'AKIA4PR6LA4IGMXMDPGU';
const SECRET_ACCESS_KEY = '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';

const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
}

const uploadImg = async (files) => {

    try {
        const arr = [];
        for (var i = 0; i < files.length; i++) {
            const res = await uploadFile(files[i], config);
            arr.push(res.location);
        }
        return arr;
    } catch (error) {
        console.log("error uploading img")
        return error;
    }

}

export default uploadImg;
