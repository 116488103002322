import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { getTeacherStudents } from "../../../../redux/Exam/action";
import { markClassVerified } from "../../../../redux/Main/action";

const StudentList = ({ teacherStudents, getTeacherStudents, markClassVerified }) => {
  const { schId, classId, sectionId, subject } = useParams();
  const [isChecked, setIsChecked] = useState(false);
  const [students, setStudents] = useState([]);

  const history = useHistory();
  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "Students", link: "#" },
  ];

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  useEffect(() => {
    // if (!classType || !classType.class) history.push("/dashboard/classes");
    getTeacherStudents({ schId, classId, sectionId });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (teacherStudents && teacherStudents.length > 0) {
      let copy = [...teacherStudents];
      copy.forEach((item, i) => {
        if (copy[i].status === null || copy[i].status === undefined) {
          copy[i].status = null;
        }
        let statusArr = item.status;
        if (item.status && statusArr.length !== 0) {
          // debugger
          statusArr.forEach((status, j) => {
            if (status.paperId.replace("15July", "") === subject) {
              let toSet = item.status[j];
              copy[i].applicationStatus = toSet.status;
            }
          });
        } else {
          copy[i].applicationStatus = null;
        }
        // console.log("data", copy);
      });
      setStudents(copy);
      // setStudents(teacherStudents)
    }
    // eslint-disable-next-line
  }, [teacherStudents]);

  const handleClick = (payload) => {
    history.push(
      `/dashboard/paper/${schId}/${classId}/${sectionId}/${subject}/${payload.StudentID}`
    );
  };

  // const handleAddClick = (e, item, i) => {
  //     // e.preventDefault();
  //     // e.stopPropagation();
  //     // e.stopImmediatePropagation();
  //     const copy = [...students]
  //     // if (copy[i].status == null) {
  //     //     copy[i].status = "-"
  //     // }
  //     if (copy[i].status === null) {
  //         copy[i].status = "Absent"
  //     } else {
  //         copy[i].status = null
  //     }
  //     setStudents(copy)

  // }

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal); // modal_scroll
    removeBodyCss();
  };

  const markClassStatus = () => {
    markClassVerified({ schId, classId, section: sectionId, subject });
    toggleModal();
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Students"
            breadcrumbItems={breadcrumbItems}
            showBtn={true}
            handleClick={() => {
              history.push(
                `/dashboard/classes`
              );
            }}
          />

          <Modal isOpen={showModal} toggle={toggleModal} scrollable={true}>
            <ModalHeader toggle={toggleModal}>Submit Report</ModalHeader>
            <ModalBody>
              <Col md={12}>
                <Row>
                  <Label className="text-danger">
                    Download class scores to verify
                  </Label>
                </Row>
                <Row>
                  <Button color="primary" id="button-class">
                    Download
                  </Button>
                </Row>
                <Row className="mt-5 ml-2 mb-2">
                  <Input
                    className="form-check-input"
                    defaultChecked={isChecked}
                    type="checkbox"
                    id="isAbsent"
                    onChange={() => setIsChecked(!isChecked)}
                  />
                  <Label className="form-check-label" htmlFor="isAbsent">
                    All scores have been added and verified.
                  </Label>
                </Row>
              </Col>
              <ModalFooter>
                <Button type="button" color="light" onClick={toggleModal}>
                  Close
                </Button>
                <Button color="success" disabled={!isChecked} onClick={markClassStatus}>
                  Submit
                </Button>
              </ModalFooter>
            </ModalBody>
          </Modal>

          <Row>
            <Col md={12}>
              <Row
                style={{ display: "flex", justifyContent: "space-between" }}
                className="mb-3"
              >
                <h3 className="mb-3">Class {classId}</h3>
                <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                  onClick={toggleModal}
                >
                  Submit Report
                </Button>
              </Row>
              <Card>
                <CardBody>
                  <Row>
                    <table className="table table-hover">
                      <thead>
                        <tr className="table-primary">
                          <th>#</th>
                          <th>Student Enrollment ID</th>
                          <th>Student Name</th>
                          <th>Class</th>
                          <th>Subject</th>
                          {/* <th>Present</th> */}
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {students &&
                          students.length > 0 &&
                          students.map((item, i) => (
                            <tr key={i} onClick={() => handleClick(item)}>
                              <td>{i + 1}</td>
                              <td>{item.StudentID}</td>
                              <td>{item.name}</td>
                              <td>
                                {classId} {sectionId}
                              </td>
                              <td>{subject}</td>
                              {/* <td>
                                                            <div
                                                                className="custom-control custom-switch mb-2"
                                                                dir="ltr"
                                                            >
                                                                <Input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    style={item.status === "Added" ? { pointerEvents: "none" } : {}}
                                                                    id={`customSwitch${i}`}
                                                                    defaultChecked={item.status === "Added" ? true : item.status === null ? true : false}
                                                                    checked={item.status === "Added" ? true : item.status === null ? true : false}
                                                                    onChange={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        if (item.status !== "Added") handleAddClick(e, item, i)
                                                                    }}
                                                                    disabled={item.status === "Added"}
                                                                />
                                                                <Label
                                                                    style={item.status === "Added" ? { pointerEvents: "none" } : {}}
                                                                    className="custom-control-label"
                                                                    htmlFor={`customSwitch${i}`}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        if (item.status !== "Added") handleAddClick(e, item, i)
                                                                    }}
                                                                >
                                                                </Label>
                                                            </div>
                                                        </td> */}
                              <td
                                className={`${
                                  item.applicationStatus === "Added"
                                    ? "text-success"
                                    : item.applicationStatus
                                    ? "text-warning"
                                    : "text-danger"
                                }`}
                              >
                                {item.applicationStatus === "Added"
                                  ? "Submitted"
                                  : item.applicationStatus === null
                                  ? "-"
                                  : "Absent"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ exam }) => ({
  classType: exam.classType,
  teacherStudents: exam.teacherStudents,
});

export default connect(mapStateToProps, { getTeacherStudents, markClassVerified })(StudentList);
