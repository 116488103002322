import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import classnames from "classnames";
import Tab1 from "./Components/Tab1";
import { getUserDetails } from "../../../../redux/Main/action";
import { connect } from "react-redux";

const Profile = ({ getUserDetails, userDetails }) => {
  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "Profile", link: "#" }
  ];

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line
  }, [])

  const [activeTab, setActiveTab] = useState("1");

  const toggle1 = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Profile" breadcrumbItems={breadcrumbItems} />
        <Row>
          <Col mg={6} xl={3}>
            <Card style={{ padding: "1.5rem" }} className="text-center">
              <CardImg
                top
                className="img-fluid rounded-circle"
                src={userDetails.gender === "M" || userDetails.gender === "Male" ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgSmojUgwjIB87c4Q0hLCAyl__oiTySWGWJUZtUNHlHjBALLzTsu_vMHYMaEwLts4QEoo&usqp=CAU" : "https://www.svgrepo.com/show/382095/female-avatar-girl-face-woman-user-4.svg"}
                alt="Skote"
              />
              <CardBody>
                <CardTitle className="mt-0">{`${userDetails.firstName} ${userDetails.lastName} `}</CardTitle>
                <CardText>{userDetails.email}</CardText>
              </CardBody>
            </Card>
          </Col>

          <Col mg={6} xl={9}>
            <Card>
              <CardBody>
                <Nav pills className="navtab-bg nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({active: activeTab === "1"})}
                      onClick={() => toggle1("1")}
                    >
                      Home
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => toggle1("2")}
                    >
                      Profile
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => toggle1("3")}
                    >
                      Messages
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: activeTab === "4" })}
                      onClick={() => toggle1("4")}
                    >
                      Settings
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1" className="p-3">
                    <Tab1 userDetails={userDetails} />
                  </TabPane>
                  <TabPane tabId="2" className="p-3">
                    <Row>
                      <Col sm="12">
                        <CardText>
                          Food truck fixie locavore, accusamus mcsweeney's marfa
                          nulla single-origin coffee squid. Exercitation +1
                          labore velit, blog sartorial PBR leggings next level
                          wes anderson artisan four loko farm-to-table craft
                          beer twee. Qui photo booth letterpress, commodo enim
                          craft beer mlkshk aliquip jean shorts ullamco ad vinyl
                          cillum PBR. Homo nostrud organic, assumenda labore
                          aesthetic magna delectus mollit. Keytar helvetica VHS
                          salvia yr, vero magna velit sapiente labore stumptown.
                          Vegan fanny pack odio cillum wes anderson 8-bit.
                        </CardText>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3" className="p-3">
                    <Row>
                      <Col sm="12">
                        <CardText>
                          Etsy mixtape wayfarers, ethical wes anderson tofu
                          before they sold out mcsweeney's organic lomo retro
                          fanny pack lo-fi farm-to-table readymade. Messenger
                          bag gentrify pitchfork tattooed craft beer, iphone
                          skateboard locavore carles etsy salvia banksy hoodie
                          helvetica. DIY synth PBR banksy irony. Leggings
                          gentrify squid 8-bit cred pitchfork. Williamsburg banh
                          mi whatever gluten-free, carles pitchfork biodiesel
                          fixie etsy retro mlkshk vice blog. Scenester cred you
                          probably haven't heard of them, vinyl craft beer blog
                          stumptown. Pitchfork sustainable tofu synth chambray
                          yr.
                        </CardText>
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId="4" className="p-3">
                    <Row>
                      <Col sm="12">
                        <CardText>
                          Trust fund seitan letterpress, keytar raw denim
                          keffiyeh etsy art party before they sold out master
                          cleanse gluten-free squid scenester freegan cosby
                          sweater. Fanny pack portland seitan DIY, art party
                          locavore wolf cliche high life echo park Austin. Cred
                          vinyl keffiyeh DIY salvia PBR, banh mi before they
                          sold out farm-to-table VHS viral locavore cosby
                          sweater. Lomo wolf viral, mustache readymade
                          thundercats keffiyeh craft beer marfa ethical. Wolf
                          salvia freegan, sartorial keffiyeh echo park vegan.
                        </CardText>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ main }) => ({
  userDetails: main.userDetails
})

export default connect(mapStateToProps, {getUserDetails})(Profile);
