import React, { useState } from 'react';
// import { uploadFile } from 'react-s3';
import uploadImg from '../../../../helpers/uploadImg';

// const S3_BUCKET = 'doe-sports-uploads';
// const REGION = 'ap-south-1';
// const ACCESS_KEY = 'AKIA4PR6LA4IGMXMDPGU';
// const SECRET_ACCESS_KEY = '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';

// const config = {
//     bucketName: S3_BUCKET,
//     region: REGION,
//     accessKeyId: ACCESS_KEY,
//     secretAccessKey: SECRET_ACCESS_KEY,
// }

const Uploads = () => {

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files);
    }

    const handleUpload = async (files) => {

        const res = await uploadImg(files); 
        console.log(res);
    }

    return <div style={{ paddingTop: "5rem" }}>
        <div>React S3 File Upload</div>
        <input type="file" multiple onChange={handleFileInput} />
        <button onClick={() => handleUpload(selectedFile)}> Upload to S3</button>
    </div>
}

export default Uploads;
